import React from 'react';
import { StyleSheetManager, ThemeProvider } from 'styled-components';
import * as theme from '@styles/theme';

//react-query
import ReactQueryProvider from '@providers/ReactQueryProvider';

//layouts
import MainLayout from '@layouts/Main';

// i18n
import I18nProvider from './i18n';

// routing
import { BrowserRouter, Route } from 'react-router-dom';
import { QueryParamProvider } from 'use-query-params';

// styles
import GlobalStyle from '@styles/global-styles';

//socket

import MemberProvider from '@providers/MemberProvider';
import DeviceChecker from './DeviceChecker';
import InjectAxiosInterceptors from './InjectAxiosInterceptors';
import AlertProvider from '@providers/AlertProvider';
import { ErrorBoundary } from '@components/ErrorBoundary';
import { AlertModalProvider } from '@providers/AlertModalProvider';
import { SettingProvider } from '@providers/SettingProvider';
import '@libs/Notification';
import setupLocatorUI from '@locator/runtime';
if (import.meta.env.DEV) {
  setupLocatorUI();
}
const BASE_URL = import.meta.env.BASE_URL;
const App = () => {
  return (
    <React.StrictMode>
      <ReactQueryProvider>
        <BrowserRouter basename={BASE_URL}>
          <ThemeProvider theme={theme}>
            <ErrorBoundary>
              <StyleSheetManager disableVendorPrefixes={import.meta.env.DEV}>
                <I18nProvider locale="en">
                  <AlertProvider>
                    <AlertModalProvider>
                      <MemberProvider>
                        <SettingProvider>
                          <MainLayout>
                            <QueryParamProvider ReactRouterRoute={Route}>
                              <InjectAxiosInterceptors />
                              <DeviceChecker />
                            </QueryParamProvider>
                          </MainLayout>
                        </SettingProvider>
                        <GlobalStyle />
                      </MemberProvider>
                    </AlertModalProvider>
                  </AlertProvider>
                </I18nProvider>
              </StyleSheetManager>
            </ErrorBoundary>
          </ThemeProvider>
        </BrowserRouter>
      </ReactQueryProvider>
      <div className="hidden">\/{`${__VERSION__}`}</div>
    </React.StrictMode>
  );
};

export default App;
