import { DateRange } from '@components/DateRangePicker/useDateRangePicker';
import { assign, createMachine } from 'xstate';
import { EFilterActionType, IAllTicketPaneEvent } from './event';
import { ETicketStatus, ESortBy } from '@enums/TicketStatus';
import { IReportResponse, ITicket, ITicketTag } from '@types';
import { cloneDeep } from 'lodash';
import { getTicket } from '../fns/findTicket';
import { DropdownItem } from '@components/Dropdown/ExtendableDropdown';
import { exportReport } from '../fns/exportTicket';

export type IDropdownTicketTag = ITicketTag & DropdownItem;

export type IAllTicketPaneContext = {
  filter: {
    keyword: string;
    onlyFollowUp: boolean;
    onlyTeamFollowUp: boolean;
    selectedChannels: string[];
    selectedAssignees: string[];
    selectedTeams: string[];
    selectedSortBy: ESortBy;
    selectedTags: string[];
    selectedStatus: ETicketStatus;
    selectedDateRange: DateRange;
    page: number;
    total: number;
    totalPages: number;
  };
  rows: ITicket[];
  report: {
    messageReport: boolean;
    ticketReport: boolean;
    selectedReport: IReportResponse;
  };
};

const updateFilterHandler = () => {
  return [
    {
      target: '#allTicketPane.fetchTableState.debounceFetching',
      actions: 'updateFilter',
      cond: (context: IAllTicketPaneContext, event: IAllTicketPaneEvent) => {
        if (event.type === EFilterActionType.UPDATE_FILTER_DATA) {
          const key: keyof typeof context.filter = event.payload.fieldName as keyof typeof context.filter;
          if (
            key === 'keyword' ||
            key === 'selectedAssignees' ||
            key === 'selectedTags' ||
            key === 'selectedChannels' ||
            key === 'selectedTeams' ||
            key === 'selectedDateRange'
          ) {
            return true;
          }
          return false;
        }
        return false;
      },
    },
    {
      target: '#allTicketPane.fetchTableState.fetchingTicket',
      actions: 'updateFilter',
    },
  ];
};

export const allTicketPane = (defaultFilter: IAllTicketPaneContext['filter']) =>
  createMachine<IAllTicketPaneContext, IAllTicketPaneEvent>(
    {
      /** @xstate-layout N4IgpgJg5mDOIC5QEMA2qAqBLAxgazABcAFZAOzAGIBVYgEQEEMBRAfQGVmGAlAYQAlWAaWYBNAOoB5bnQDaABgC6iUAAcA9rCyEs6sipAAPRACYAzADozZgKwBOGwBZn8mzfkA2O44A0IAJ6ItgCMFgAc9tZm8mEA7HZxsQC+SX5omLgEJORUtIwsHMwAMsy8LHSsAgwActXF7ArKSCAaWjp6BsYIwZYmXjZmHtE2seaOtn6BCCZuFo6eJibBsbH28qMpaejY+ESkFDT0TGycJWXMFQzs7ACSAOJ1zA1KBq3auvrNXcEmFvL-AMBAOCvgCiGCNjCFgcZmCwXkjiGYT6yVSIHSOyy+1yRwKp1K5Q4GCY1GeTTUmneHS+phsFnhEI8HmWZhM-zsg0miA8v0cdn5-Ihy0RJkcm3R20yexyh3ybDlrG4NTuzEar0p7U+oC6jliFhMYTs6xMDlifNiYVBUx+KTRZHUEDgBgxUuyFHVbQ+nUQAFp4RYzR4YosbB5EfN1lyEI5llYfs4XP8PMjxS7dm6wBYAGZYVCEMAAJ3YhGQ+Y9VK1RiCepmZjsyzNtls-Kj7kc4XjjhsPR+MWCHlTkvT2OzufzRZL+YsWAgqDA5c13u6-fCMWZjhNYQ8oYRrbsv1hoq7W7GYWCg4yw5yo7zheLpczOYLsEIADEiDgABZYMhQBdemkEAifUBnrFZxgGDwo23eQOyPdwQXkbwxTRNMsWvLMP0-e8y2aN5F0Aswa1AhsIObOwo1GUJD0cCI+nrCEbAvTFpQobMsJwzMZznf9qW1RBgNrMDG0gqMwhiODaKDf4ZjcW0kiAA */
      id: 'allTicketPane',
      type: 'parallel',
      context: {
        filter: defaultFilter,
        rows: [],
        report: {
          messageReport: false,
          ticketReport: false,
          selectedReport: {} as IReportResponse,
        },
      },
      states: {
        filterState: {
          initial: 'idle',
          states: {
            idle: {},
          },
          on: {
            UPDATE_FILTER_DATA: updateFilterHandler(),
            CLEAR_ALL_FILTER_DATA: {
              target: '#allTicketPane.fetchTableState.fetchingTicket',
              actions: assign({
                filter: (context, event) => {
                  if (event.type === EFilterActionType.CLEAR_ALL_FILTER_DATA) {
                    return cloneDeep({
                      ...defaultFilter,
                      selectedAssignees: [],
                      selectedChannels: [],
                      selectedTags: [],
                      selectedTeams: [],
                      selectedDateRange: {} as DateRange,
                    });
                  }
                  return context.filter;
                },
              }),
            },
          },
        },
        fetchTableState: {
          initial: 'fetchingTicket',
          states: {
            idle: {},
            debounceFetching: {
              on: {
                UPDATE_FILTER_DATA: updateFilterHandler(),
              },
              after: {
                450: {
                  target: 'fetchingTicket',
                },
              },
            },
            fetchingTicket: {
              invoke: {
                src: async (context, event) => await getTicket(context, event),
                onDone: {
                  target: 'fetchTicketSuccess',
                  actions: ['updateDatePagination', 'updateTicketList'],
                },
                onError: {
                  target: 'fetchTicketFailed',
                },
              },
            },
            fetchTicketSuccess: {},
            fetchTicketFailed: {},
          },
        },
        report: {
          initial: 'idle',
          states: {
            idle: {},
            modalState: {
              initial: 'idle',
              states: {
                idle: {},
                exporting: {
                  invoke: {
                    id: 'export-report',
                    src: 'exportReport',
                    onDone: {
                      target: 'exportSuccess',
                      actions: 'updateSelectedDownloadReport',
                    },
                    onError: 'exportFailed',
                  },
                },
                exportSuccess: {
                  on: {
                    EXPORT_REPORT_IDLE: '#allTicketPane.report.idle',
                  },
                },
                exportFailed: {},
              },
              on: {
                EXPORT_REPORT_CONFIRM: '.exporting',
                EXPORT_REPORT_CANCEL: '#allTicketPane.report.idle',
                UPDATE_SELECTED_REPORT: {
                  actions: 'updateSelectedReport',
                },
              },
            },
          },
          on: {
            EXPORT_REPORT: '.modalState',
          },
        },
      },
    },
    {
      actions: {
        updateSelectedReport: assign({
          report: (context, event) => {
            if (event.type === EFilterActionType.UPDATE_SELECTED_REPORT) {
              const { payload } = event;
              return {
                ...context.report,
                [payload.key]: payload.value,
              };
            }
            return context.report;
          },
        }),
        updateDatePagination: assign({
          filter: (context, event) => {
            if (event.type === EFilterActionType.DONE_FETCHING_LIST) {
              const { data } = event.data;
              return {
                ...context.filter,
                page: data.page,
                total: data.total,
                totalPages: data.totalPages,
              };
            }
            return context.filter;
          },
        }),
        updateTicketList: assign({
          rows: (context, event) => {
            if (event.type === EFilterActionType.DONE_FETCHING_LIST) {
              const { data } = event.data;
              return data.rows;
            }
            return context.rows;
          },
        }),
        updateSelectedDownloadReport: assign({
          report: (context, event) => {
            if (event.type === EFilterActionType.UPDATE_SELECTED_DOWNLOAD_REPORT) {
              const { data } = event;
              return {
                ...context.report,
                selectedReport: data,
              };
            }
            return context.report;
          },
        }),
        updateFilter: assign({
          filter: (context, event) => {
            if (event.type === EFilterActionType.UPDATE_FILTER_DATA) {
              const newFilter = cloneDeep(context.filter);
              const key: keyof typeof newFilter = event.payload.fieldName as keyof typeof newFilter;
              // reset page if filter change
              if (
                (key === 'selectedChannels' || key === 'selectedAssignees' || key === 'selectedTags') &&
                Array.isArray(event.payload.value) &&
                event.payload.value.length === 0
              ) {
                newFilter.page = 1;
                newFilter.totalPages = 0;
                newFilter.total = 0;
              }

              // if key is not page reset page to 1
              if (key !== 'page') {
                newFilter.page = 1;
              }

              return {
                ...newFilter,
                [key]: event.payload.value,
              };
            }
            return context.filter;
          },
          rows: (context, event) => {
            // clear rows if channel is empty or assignee is empty
            if (event.type === EFilterActionType.UPDATE_FILTER_DATA) {
              const newFilter = cloneDeep(context.filter);
              const key: keyof typeof newFilter = event.payload.fieldName as keyof typeof newFilter;
              if (
                key === 'selectedChannels' &&
                Array.isArray(event.payload.value) &&
                event.payload.value.length === 0
              ) {
                return [];
              }
              if (
                key === 'selectedAssignees' &&
                Array.isArray(event.payload.value) &&
                event.payload.value.length === 0
              ) {
                return [];
              }
            }
            return context.rows;
          },
        }),
      },
      services: {
        exportReport: async (context, _) => {
          try {
            const { data } = await exportReport(context);
            return data;
          } catch (error) {
            console.error('Error exporting report', error);
            throw error;
          }
        },
      },
    },
  );
