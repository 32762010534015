import React, { useMemo } from 'react';
import { Upload } from 'antd';
import { MessageComposerContainer, IconButton, TextAreaContainer } from './style';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import TextArea from '@components/TextArea';
import PreviewFileModal from '../PreviewFileModal';
import { useTheme } from 'styled-components';
import { onPressEnterAndShift } from '@utils/keyboard';
import { BOTS, IFileHandler, IMessageBoxHandler } from '@types';
import { faCommentAltDots, faPaperPlane, faPaperclip } from '@fortawesome/pro-solid-svg-icons';
import { useComposerManager } from '@providers/ComposerManagerProvider';
import { SwitchFade } from '@components/SwitchFade';
import { Counter, Font12G5W400, Font14G5W400, Font14WhiteW600 } from '@components/UtilsComponent';
import { PrimaryButton } from '@components/Button';
import { useMaxWidth } from '@hooks/useMaxWidth';
import { Fade } from '@components/Fade';
import { TemplateComposer } from '@components/TemplateComposer';
import useMeasure from 'react-use-measure';
import { VAPlaceholder } from '@components/VASuggestion';
import { CombineAllMessage } from '@model/MessageItem';
import { AgentReplyMessage } from '@components/AgentReplyMessage';
export type IMessageComposer = {
  messageBoxHandler: IMessageBoxHandler;
  fileHandler: IFileHandler;
  lastMessage: CombineAllMessage | false;
  platform?: string;
  maxLength?: number;
  ticketId?: string;
  enableVa: boolean;
};

const MessageComposer: React.FC<IMessageComposer> = ({
  platform,
  messageBoxHandler,
  fileHandler,
  maxLength,
  lastMessage,
  ticketId,
  enableVa,
}: IMessageComposer) => {
  const theme: any = useTheme();
  const { composer, preview } = useComposerManager();
  const { showTemplateComposer, onToggleComposer } = composer;
  const { selectedTemplate, onSelectTemplate } = preview;
  const { divRef, maxWidth } = useMaxWidth(showTemplateComposer);
  const [ref, bounds] = useMeasure();
  const composerHeight = useMemo(() => {
    if (showTemplateComposer && bounds.height) {
      return 200 + bounds.height;
    } else if (bounds.height !== 0) {
      return `${bounds.height}px`;
    } else {
      return '68px';
    }
  }, [bounds.height, showTemplateComposer]);
  return (
    <div className="border-t border-solid border-line-default transition-all duration-200 relative z-[8]">
      {ticketId && enableVa && lastMessage && !showTemplateComposer ? (
        <VAPlaceholder
          currentMessage={messageBoxHandler.textMessage}
          lastMessage={lastMessage}
          ticketId={ticketId}
          handleInsertIntoField={(text: string) => {
            messageBoxHandler.handleTextInputChange(messageBoxHandler.textMessage + text);
          }}
        />
      ) : null}
      <div
        style={{
          padding: '16px 24px 24px 24px',
        }}
      >
        <MessageComposerContainer
          style={{
            height: composerHeight,
          }}
          className={`transition-all duration-200 z-[9]`}
        >
          <div ref={ref}>
            {platform === 'line' ? <AgentReplyMessage /> : null}

            <div className="flex gap-x-[8px] items-center pb-[8px] relative">
              <Upload beforeUpload={fileHandler.beforeUpload} multiple showUploadList={false}>
                <IconButton ref={fileHandler.uploadRef}>
                  <FontAwesomeIcon icon={faPaperclip} />
                </IconButton>
              </Upload>
              <IconButton
                onClick={() => {
                  onToggleComposer();
                  onSelectTemplate(null);
                }}
              >
                <FontAwesomeIcon
                  icon={faCommentAltDots}
                  style={{
                    color: showTemplateComposer ? theme.PRIMARY : '',
                  }}
                />
              </IconButton>
              {/* Text composer */}
              <SwitchFade
                isEl1Show={!showTemplateComposer}
                duration={200}
                el1={{
                  className: 'w-full',
                  el: (
                    <div className={`w-full flex justify-between items-center`}>
                      <TextAreaContainer>
                        <div className="relative">
                          <div id="va-composer-container"></div>
                          <TextArea
                            id="message-composer"
                            onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
                              messageBoxHandler.handleTextInputChange(e.target.value)
                            }
                            value={messageBoxHandler.textMessage}
                            autoSize={{
                              minRows: 1,
                              maxRows: 4,
                            }}
                            maxLength={maxLength}
                            placeholder="Message"
                            onKeyDown={(e) => {
                              const keydownFn = onPressEnterAndShift(e);
                              keydownFn(() => {
                                if (messageBoxHandler.disabledSendButton) return;
                                messageBoxHandler.handleSendMessage(messageBoxHandler.textMessage);
                              });
                            }}
                          />
                          <Counter className="absolute right-0">
                            <Font12G5W400>{`${messageBoxHandler.textMessage.length} / ${maxLength}`}</Font12G5W400>
                          </Counter>
                        </div>
                      </TextAreaContainer>
                      <IconButton
                        id="send-message-btn"
                        data-testid="send-message-btn"
                        disabled={messageBoxHandler.disabledSendButton}
                        onClick={(e) => {
                          messageBoxHandler.handleSendMessage(messageBoxHandler.textMessage);
                        }}
                      >
                        <FontAwesomeIcon
                          icon={faPaperPlane}
                          color={!messageBoxHandler.disabledSendButton ? theme.PRIMARY : 'rgba(0, 0, 0, 0.18)'}
                        />
                      </IconButton>
                    </div>
                  ),
                }}
                el2={{
                  className: 'flex-1',
                  el: (
                    <div className={`w-full flex justify-between items-center`}>
                      <div
                        className="w-full"
                        ref={divRef}
                        style={{
                          maxWidth: maxWidth ? `${maxWidth}px` : 'auto',
                        }}
                      >
                        <Font14G5W400 className="w-full block overflow-ellipsis whitespace-nowrap overflow-hidden">
                          {selectedTemplate?.name}
                        </Font14G5W400>
                      </div>
                      <PrimaryButton
                        size="middle"
                        onClick={() => {
                          if (selectedTemplate) {
                            messageBoxHandler.handleSendTemplate(selectedTemplate);
                          }
                        }}
                      >
                        <Font14WhiteW600 className="text-white mr-[8px]">Send template</Font14WhiteW600>
                        <FontAwesomeIcon className="text-[16px]" icon={faPaperPlane} />
                      </PrimaryButton>
                    </div>
                  ),
                }}
              />
            </div>
          </div>
          <Fade className="w-full" isShow={showTemplateComposer} duration={200}>
            <TemplateComposer
              platform={platform}
              onSelectTemplate={(template: BOTS.ITemplateApi | null) => {
                onSelectTemplate(template);
              }}
            />
          </Fade>
          <PreviewFileModal
            visible={fileHandler.isFileProcessing}
            files={fileHandler.selectedFiles}
            onConfirmUpload={fileHandler.onConfirmUpload}
            onCancelUpload={fileHandler.onCancelUpload}
            rejectFiles={fileHandler.rejectedFiles}
            isFailed={fileHandler.isFileUploadFailed}
            isLoading={fileHandler.isFileUploading}
            errorMessage={fileHandler.errorMessage}
          />
        </MessageComposerContainer>
      </div>
    </div>
  );
};

export default MessageComposer;
