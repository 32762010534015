import { useEffect, Key, useState } from 'react';
import { useMachine } from '@xstate/react';
import { Form } from 'antd';
import newTeamEditorMachine from './newTeamEditorMachine';
import { useAlert } from 'react-alert';
import useTranslation from '@hooks/useTranslation';
import { useHistory } from 'react-router-dom';
import useForceUpdate from '@hooks/useForceUpdate';
import { IMember, IPublicHolidayFE, IWorkHourDays } from '@types';
import { ERole } from '@enums/Role';
import { IVaHandler } from '../VaBlock';
import { cloneDeep } from 'lodash';
import { DEFAULT_WORKING_HOURS, PUBLIC_HOLIDAY_KEYS } from '@configs/constants';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';

type ISubmitParams = {
  name: string;
  department: string;
  default?: boolean;
  workingHourEnabled: boolean;
  enablePublicHolidays: boolean;
  enableOffHourAssignment: boolean;
  enableSLAPause: boolean;
  enableOffHourAutoReply: boolean;
};

const useNewTeamEditor = () => {
  const [form] = Form.useForm();
  const alert = useAlert();
  const [vadateErrorMessage, setVadateErrorMessage] = useState('');
  const history = useHistory();
  const t = useTranslation();
  const forceUpdate = useForceUpdate();
  const [publicHolidayTimes, setPublicHolidayTimes] = useState<IPublicHolidayFE>({
    enabled: false,
    timeslots: cloneDeep(DEFAULT_WORKING_HOURS),
  });
  const [autoReply, setAutoReply] = useState<{
    enableOffHourAutoReply: boolean;
    offHourAutoReplyTemplateId: string;
  }>({
    enableOffHourAutoReply: false,
    offHourAutoReplyTemplateId: '',
  });
  const [timeSlotState, setTimeSlotState] = useState<IWorkHourDays>({
    monday: cloneDeep(DEFAULT_WORKING_HOURS),
    tuesday: cloneDeep(DEFAULT_WORKING_HOURS),
    wednesday: cloneDeep(DEFAULT_WORKING_HOURS),
    thursday: cloneDeep(DEFAULT_WORKING_HOURS),
    friday: cloneDeep(DEFAULT_WORKING_HOURS),
    saturday: cloneDeep(DEFAULT_WORKING_HOURS),
    sunday: cloneDeep(DEFAULT_WORKING_HOURS),
  });

  const [selectedVas, setSelectedVas] = useState<{
    firstResponse: IVaHandler['selected'];
    copilot: IVaHandler['selected'];
  }>({
    firstResponse: {
      id: '',
      name: '',
    },
    copilot: {
      id: '',
      name: '',
    },
  });
  const [current, send] = useMachine(newTeamEditorMachine, {
    devTools: import.meta.env.DEV,
  });

  useEffect(() => {
    if (current.matches('saveSuccess')) {
      alert.success(t('livechat.team.management.member.editor.alert.add.new.team.success'), {
        timeout: 2000,
        onOpen: () => {
          history.goBack();
        },
      });
    }
  }, [current]);

  const getWorkingHourStatus = (payload: ISubmitParams) => {
    if (payload.default) {
      return false;
    }
    return payload.workingHourEnabled || false;
  };

  const onSubmit = (values: ISubmitParams) => {
    const { selectedMembers } = current.context;
    const payload = {
      default: values.default ?? false,
      agents: selectedMembers,
      name: values.name,
      department: values.department,
      workHours: {
        enabled: getWorkingHourStatus(values),
        enablePublicHolidays: values.enablePublicHolidays || false,
        enableOffHourAssignment: values.enableOffHourAssignment || false,
        enableSLAPause: values.enableSLAPause || false,
        enableOffHourAutoReply: autoReply.enableOffHourAutoReply || false,
        offHourAutoReplyTemplateId: autoReply.offHourAutoReplyTemplateId || null,
        days: {
          ...timeSlotState,
          [PUBLIC_HOLIDAY_KEYS]: publicHolidayTimes.timeslots,
        },
      },
    };
    if (payload.workHours.enableOffHourAutoReply && !payload.workHours.offHourAutoReplyTemplateId) {
      setVadateErrorMessage(t('livechat.team.management.member.editor.alert.select.auto.reply.template.required'));
      return;
    }
    setVadateErrorMessage('');
    send('SUBMIT_FORM', {
      payload: {
        ...payload,
        virtualAgentConfig: {
          active: selectedVas.firstResponse.id || undefined,
          passive: selectedVas.copilot.id || undefined,
        },
      },
    });
  };

  const isCheckBoxDisabled = (record: IMember) => {
    const isDefaultTeam = form.getFieldValue('default');
    const isBotRole = record.roles.includes(ERole.BOT);

    return !isDefaultTeam && isBotRole;
  };

  const handleSelectMembers = (selectedRowKeys: Key[]) => {
    send('SET_SELECTED_MEMBERS', { data: selectedRowKeys });
  };

  const handlePageChange = (page: number) => {
    send('FETCH_MEMBER', { page });
  };

  const filterOutSelectedBotMembers = () => {
    if (!form.getFieldValue('default')) {
      send('FILTER_OUT_BOT_MEMBER');
    }
    forceUpdate();
  };

  return {
    current,
    form,
    vadateErrorMessage,
    workHourHandler: {
      publicHolidayTimes,
      timeSlotState,
      autoReply,
      setTimeSlotState,
      setPublicHolidayTimes,
      setAutoReply,
    },
    vaHandler: {
      selectedVas,
      onSelectActiveVa: (id: string, name: string) => {
        setSelectedVas((prev) => ({
          ...prev,
          firstResponse: {
            id,
            name,
          },
        }));
      },
      onSelectPassiveVa: (id: string, name: string) => {
        setSelectedVas((prev) => ({
          ...prev,
          copilot: {
            id,
            name,
          },
        }));
      },
    },
    onCheckPublicHoliday: (e: CheckboxChangeEvent) => {
      setPublicHolidayTimes((prev) => {
        return {
          ...prev,
          enabled: e.target.checked,
        };
      });
    },
    send,
    onSubmit,
    forceUpdate,
    filterOutSelectedBotMembers,
    isCheckBoxDisabled,
    handleSelectMembers,
    handlePageChange,
  };
};

export default useNewTeamEditor;
