import { findTicket as findTicketApi } from '@api/livechat/TicketManagement/ticket';
import _, { isEmpty } from 'lodash';
import { IAllTicketPaneContext } from '../machine';
import { IAllTicketPaneEvent } from '../machine/event';
import { ETicketStatus } from '@enums/TicketStatus';
import { TICKET_MANAGEMENT_MAX_API_SIZE } from '@configs/constants';

export type FindTicketParams = {
  keyword?: string;
  assignee?: string[];
  status?: string[];
  channel?: string[];
  tags?: string[];
  team?: string[];
  onlyFollowUp?: boolean;
  onlyTeamFollowUp?: boolean;
  page?: number;
  pageSize?: number;
  date?: {
    to?: number;
    from?: number;
  };
  sortFields: string[];
};

const shouldInsertKeyword = (keyword?: string) => {
  return keyword && keyword.length > 0;
};

const shouldInsertDate = (date?: { to?: Date; from?: Date }) => {
  return date?.to && date?.from;
};

export const getTicket = async (context: IAllTicketPaneContext, _event: IAllTicketPaneEvent) => {
  try {
    const {
      keyword,
      onlyFollowUp,
      onlyTeamFollowUp,
      selectedAssignees,
      selectedChannels,
      selectedDateRange,
      selectedStatus,
      selectedTags,
      selectedSortBy,
      selectedTeams,
      page,
    } = context.filter;

    const params: FindTicketParams = {
      sortFields: [selectedSortBy],
      keyword: '',
      assignee: undefined,
      status: undefined,
      channel: undefined,
      onlyFollowUp: false,
      onlyTeamFollowUp: false,
      page: 0,
      pageSize: 0,
    };
    if (shouldInsertKeyword(keyword)) {
      params.keyword = keyword;
    }
    if (!isEmpty(selectedChannels)) {
      params.channel = selectedChannels;
    }
    if (!isEmpty(selectedAssignees)) {
      params.assignee = selectedAssignees;
    }
    if (!isEmpty(selectedTags)) {
      params.tags = selectedTags;
    }
    if (!isEmpty(selectedTeams)) {
      params.team = selectedTeams;
    }
    if (selectedStatus !== ETicketStatus.ALL) {
      params.status = [selectedStatus];
    }
    if (shouldInsertDate(selectedDateRange)) {
      params.date = _.omitBy(
        {
          to: selectedDateRange?.to?.getTime(),
          from: selectedDateRange?.from?.getTime(),
        },
        _.isNil,
      );
    }
    if (onlyFollowUp) {
      params.onlyFollowUp = onlyFollowUp;
    }
    if (onlyTeamFollowUp) {
      params.onlyTeamFollowUp = onlyTeamFollowUp;
    }
    if (page) {
      params.page = page;
    }

    params.pageSize = TICKET_MANAGEMENT_MAX_API_SIZE;
    const result = await findTicketApi(params);

    return result;
  } catch (error) {
    console.error('Error in getTicket', error);
  }
};
