import React from 'react';
import {
  Container,
  TopSection,
  DisplayBox,
  Empty,
  SearchWrap,
  Content,
  ListContainer,
  PreviewContainer,
  ListItemWrapper,
  ListHeader,
  ListItem,
  SpinContainer,
  TopSectionHidePreview,
} from './style';
import BOT_ICON from '@assets/images/bot-icon.svg';
import { Col, Form, Row, Spin } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/pro-solid-svg-icons';
import { PreviewWrapper } from '@components/MessagePreview';
import { BOTS } from '@types';
import { Button, TertiaryButton } from '@components/Button';
import { Font16G1W400, Font16G3W500, Font16G5W400, Font20G1W600, Frame, Spacing } from '@components/UtilsComponent';
import { ConfirmModal } from '@components/Modal/ConfirmModal';
import SearchInput from '@components/SearchInput';
import { LoadingWrap } from '@components/Loading/style';
import LoadingCircle from '@components/Loading';
import { useTemplateSelector } from '@hooks/useTemplateSelector';
import { getTemplates } from '@api/template';
const EMPTY_TITLE = 'No template selected';

type ITemplateSelectorProps = {
  selectedTemplate: BOTS.ITemplateApi | null;
  headerLabel?: string;
  hidePreview?: boolean;
  previewMode?: boolean;
  disabled?: boolean;
  onConfirmSelectTemplate: (template: BOTS.ITemplateApi) => void;
  onRemoveTemplate?: () => void;
};

export const TemplateSelector = ({
  headerLabel = 'Template',
  selectedTemplate,
  hidePreview,
  previewMode = false,
  disabled,
  onConfirmSelectTemplate,
  onRemoveTemplate,
}: ITemplateSelectorProps) => {
  const {
    innerSelectedTemplate,
    current,
    visible,
    isLoadingMore,
    onSearch,
    onUpdateKeyword,
    onToggleModal,
    onScroll,
    onSelectTemplate,
  } = useTemplateSelector(selectedTemplate, getTemplates);

  const _renderHeaderAction = () => {
    if (selectedTemplate) {
      return (
        <>
          <Col>
            <TertiaryButton disabled={disabled} onClick={() => (onRemoveTemplate ? onRemoveTemplate() : undefined)}>
              Remove
            </TertiaryButton>
          </Col>
          <Col>
            <TertiaryButton onClick={onToggleModal} disabled={disabled}>
              Change template
            </TertiaryButton>
          </Col>
        </>
      );
    }
    return null;
  };

  const _renderHeaderActionHidePreview = () => {
    if (!selectedTemplate) {
      return (
        <>
          <Col>
            <Button size="large" onClick={onToggleModal} disabled={disabled}>
              Select Template
            </Button>
          </Col>
          <Col>
            <Font16G5W400>Please select template</Font16G5W400>
          </Col>
        </>
      );
    }
    return (
      <>
        <Col>
          <Button size="large" onClick={onToggleModal} disabled={disabled}>
            Change template
          </Button>
        </Col>
        <Col>
          <TertiaryButton
            disabled={disabled}
            size="large"
            onClick={() => (onRemoveTemplate ? onRemoveTemplate() : undefined)}
          >
            Remove
          </TertiaryButton>
        </Col>
        <Col>
          <Font16G1W400>{selectedTemplate.name}</Font16G1W400>
        </Col>
      </>
    );
  };

  const _renderContent = () => {
    if (hidePreview) return null;
    if (!selectedTemplate) {
      return (
        <DisplayBox selectedTemplate={!!selectedTemplate} className="template-selector-display-box">
          <Empty>
            <img src={BOT_ICON} alt="bot-icon" />
            <Spacing height={13} />
            <Font20G1W600>{EMPTY_TITLE}</Font20G1W600>
            <Spacing height={32} />
            {!previewMode && (
              <TertiaryButton disabled={disabled} onClick={onToggleModal}>
                Select template
              </TertiaryButton>
            )}
          </Empty>
        </DisplayBox>
      );
    }

    return (
      <DisplayBox selectedTemplate={!!selectedTemplate}>
        <PreviewWrapper message={selectedTemplate} showName />
      </DisplayBox>
    );
  };
  const _renderHeader = () => {
    if (previewMode) return null;
    if (hidePreview) {
      return (
        <TopSectionHidePreview>
          <Font16G3W500>{headerLabel}</Font16G3W500>
          <Spacing height={16} />
          <Row gutter={[16, 0]} align="middle">
            {_renderHeaderActionHidePreview()}
          </Row>
        </TopSectionHidePreview>
      );
    }
    if (!hidePreview) {
      if (!headerLabel) {
        return (
          <>
            <Font16G3W500>{headerLabel}</Font16G3W500>
            <Row gutter={[8, 0]} justify="end">
              {_renderHeaderAction()}
            </Row>
          </>
        );
      }
      return (
        <TopSection>
          <Font16G3W500>{headerLabel}</Font16G3W500>
          <Row gutter={[8, 0]} justify="end">
            {_renderHeaderAction()}
          </Row>
        </TopSection>
      );
    }
  };
  return (
    <Container>
      {_renderHeader()}
      {selectedTemplate && !previewMode ? <Spacing height={16} /> : null}
      {_renderContent()}
      <ConfirmModal
        visible={visible}
        width={800}
        isDisabledCancelBtn={!innerSelectedTemplate}
        confirmBtnTxt={'Confirm'}
        cancelBtnTxt={'Cancel'}
        handleClose={() => {
          onSelectTemplate(null);
          onToggleModal();
        }}
        handleConfirm={() => {
          if (innerSelectedTemplate) {
            onConfirmSelectTemplate(innerSelectedTemplate);
          }

          onToggleModal();
        }}
      >
        <Font20G1W600>Select template</Font20G1W600>
        <Spacing height={16} />
        <Form onFinish={onSearch}>
          <SearchWrap>
            <Frame width={'328px'} height={'48px'}>
              <SearchInput
                size="large"
                value={current.context.searchKeyword}
                onChange={(e) => onUpdateKeyword(e.target.value)}
                onClear={() => {
                  onUpdateKeyword('');
                }}
              />
            </Frame>
            <div>
              <Button size="large" onClick={onSearch}>
                Search
              </Button>
            </div>
          </SearchWrap>
        </Form>
        <Spacing height={24} />
        <SpinContainer>
          <Spin
            indicator={
              <LoadingWrap>
                <LoadingCircle />
              </LoadingWrap>
            }
            spinning={current.matches('fetching')}
          >
            <Content>
              <ListContainer>
                <div>
                  <ListHeader>
                    <div></div>
                    <div>TEMPLATE NAME</div>
                  </ListHeader>
                </div>
                <ListItemWrapper onScroll={onScroll}>
                  {current.context.list.data.map((item: BOTS.ITemplateApi) => {
                    return (
                      <ListItem
                        title={item.name}
                        isChecked={innerSelectedTemplate?.id === item.id}
                        key={item.id}
                        onClick={() => onSelectTemplate(item)}
                      >
                        <div>{innerSelectedTemplate?.id === item.id && <FontAwesomeIcon icon={faCheck} />}</div>
                        <div>{item.name}</div>
                      </ListItem>
                    );
                  })}
                  {isLoadingMore && <LoadingCircle />}
                </ListItemWrapper>
              </ListContainer>
              <PreviewContainer>
                {innerSelectedTemplate ? <PreviewWrapper message={innerSelectedTemplate} /> : null}
              </PreviewContainer>
            </Content>
          </Spin>
        </SpinContainer>
      </ConfirmModal>
    </Container>
  );
};
