import React, { useEffect, useState } from 'react';
import { TemplateSelector } from '@components/TemplateSelector';
import { BOTS } from '@types';
import { useQuery } from 'react-query';
import { getTemplateById } from '@api/template';

type IAutoReplySelectorProps = {
  templateId: string | null;
  onConfirmSelectTemplate: (template: BOTS.ITemplateApi | null) => void;
};

export const AutoReplySelector = ({ templateId, onConfirmSelectTemplate }: IAutoReplySelectorProps) => {
  const response = useQuery({
    queryKey: ['templateId'],
    queryFn: () => {
      if (!templateId) return;
      return getTemplateById(templateId);
    },
    enabled: !!templateId,
    cacheTime: 0,
  });
  const [innerSelectTemplate, setInnerSelectTemplate] = useState<BOTS.ITemplateApi | null>(null);

  useEffect(() => {
    if (response.data) {
      setInnerSelectTemplate(response.data);
    }
  }, [response.data]);

  return (
    <>
      <TemplateSelector
        selectedTemplate={innerSelectTemplate}
        onConfirmSelectTemplate={(template: BOTS.ITemplateApi) => {
          setInnerSelectTemplate(template);
          onConfirmSelectTemplate(template);
        }}
        onRemoveTemplate={() => {
          setInnerSelectTemplate(null);
          onConfirmSelectTemplate(null);
        }}
      />
    </>
  );
};
