import { slaCalculator } from '@utils/ticketSla';
import dayjs from 'dayjs';
import { isNil } from 'lodash';
import { useState, useEffect } from 'react';

export type IUseSlaCounterParams = {
  slaHour?: number;
  slaMinute?: number;
  startTicketTime?: string | number | Date | dayjs.Dayjs;
  isPaused?: boolean;
  pauseSLA?: boolean;
  pauseSLATotalMs?: number;
  pauseSLALatestAt?: string;
  stopSLA?: string | number | Date | dayjs.Dayjs;
};

export type IUseSlaState =
  | string
  | {
      slaHours: number;
      slaMinutes: string | number;
      slaSeconds: string | number;
      isOverdue: boolean;
    };

export const useSlaCounter = ({
  slaHour,
  slaMinute,
  startTicketTime,
  pauseSLA,
  pauseSLATotalMs,
  pauseSLALatestAt,
  stopSLA,
}: IUseSlaCounterParams) => {
  const [slaCounter, setSlaCounter] = useState<IUseSlaState>('');

  useEffect(() => {
    if (pauseSLA) {
      const result = slaCalculator({
        sla: {
          hr: !isNil(slaHour) ? slaHour : -1,
          min: !isNil(slaMinute) ? slaMinute : -1,
        },
        startTicketTime: startTicketTime,
        pauseSLA: pauseSLA,
        pauseSLATotalMs: pauseSLATotalMs,
        pauseSLALatestAt: pauseSLALatestAt,
      });
      return setSlaCounter(result);
    }

    if (stopSLA) {
      const result = slaCalculator({
        sla: {
          hr: !isNil(slaHour) ? slaHour : -1,
          min: !isNil(slaMinute) ? slaMinute : -1,
        },
        now: dayjs(stopSLA) as dayjs.Dayjs,
        startTicketTime: startTicketTime,
        pauseSLA: pauseSLA,
        pauseSLATotalMs: pauseSLATotalMs,
        pauseSLALatestAt: pauseSLALatestAt,
      });
      // force time
      return setSlaCounter(result);
    }

    const interval = setInterval(() => {
      const result = slaCalculator({
        sla: {
          hr: !isNil(slaHour) ? slaHour : -1,
          min: !isNil(slaMinute) ? slaMinute : -1,
        },
        startTicketTime: startTicketTime,
        pauseSLA: pauseSLA,
        pauseSLATotalMs: pauseSLATotalMs,
        pauseSLALatestAt: pauseSLALatestAt,
      });
      setSlaCounter(result);
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  return { slaCounter };
};
