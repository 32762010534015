import React from 'react';
import { useSlaCounter } from './useSlaCounter';
import { SlaContainer } from './style';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStopwatch } from '@fortawesome/pro-regular-svg-icons';
import { Font12G3W500 } from '@components/UtilsComponent';
import { ITicket } from '@types';
import { isNil } from 'lodash';

type ISlaTextProps = {
  ticket?: ITicket | null;
};

export const SlaText = ({ ticket }: ISlaTextProps) => {
  const slaHour = !isNil(ticket?.SLATime?.hour) ? ticket?.SLATime?.hour : -1;
  const slaMinute = !isNil(ticket?.SLATime?.minute) ? ticket?.SLATime?.minute : -1;
  const startTicketTime = ticket?.startSLA ? ticket.startSLA : undefined;
  const stopSLA = ticket?.stopSLA ? ticket.stopSLA : undefined;
  const { slaCounter } = useSlaCounter({
    slaHour,
    slaMinute,
    startTicketTime,
    pauseSLA: ticket?.pauseSLA,
    pauseSLATotalMs: ticket?.pauseSLATotalMs,
    pauseSLALatestAt: ticket?.pauseSLALatestAt,
    stopSLA: stopSLA,
  });

  if (!ticket) {
    return null;
  }

  if (!startTicketTime) {
    return (
      <>
        <FontAwesomeIcon icon={faStopwatch} /> <span>0:00</span>
      </>
    );
  }
  if (typeof slaCounter === 'object') {
    return (
      <SlaContainer isPause={ticket?.pauseSLA || false} isOverdue={slaCounter.isOverdue}>
        <>
          <FontAwesomeIcon icon={faStopwatch} />
          <Font12G3W500>
            {slaCounter.slaHours}:{slaCounter.slaMinutes}:{slaCounter.slaSeconds}
          </Font12G3W500>
        </>
      </SlaContainer>
    );
  }
  return null;
};
